const env = process.env.REACT_APP_ENV || '!!env!!';

export const settings = () => {
  let showLicensePlate;
  let country;
  let fallbackLanguage;
  let mapLatLong;
  let currencySymbol = '€';
  if (env === 'nl') {
    country = 'nl';
    showLicensePlate = true;
    fallbackLanguage = 'nl';
    mapLatLong = [52.1, 4.9];
  } else if (env === 'be') {
    country = 'be';
    showLicensePlate = false;
    fallbackLanguage = 'en';
    mapLatLong = [50.5, 4.7];
  } else if (env === 'de') {
    country = 'de';
    showLicensePlate = false;
    fallbackLanguage = 'de';
    mapLatLong = [51.7, 10];
  } else if (env === 'se') {
    country = 'se';
    showLicensePlate = false;
    fallbackLanguage = 'en';
    mapLatLong = [51.7, 10];
    currencySymbol = 'KR';
  } else throw Error('ENV not set');
  return { country, showLicensePlate, fallbackLanguage, mapLatLong, currencySymbol };
};
