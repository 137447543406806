import { instance } from './instance';
import { CarResult } from '../../types';

export enum EmailTemplate {
  SHARE_CAR = 'share-car',
  SHARE_TAXATION = 'share-taxation',
  SHARE_PRICE_PROPOSAL = 'share-price-proposal',
  SHARE_CAR_MESSAGE = 'share-car-message',
  SHARE_CSM_COMMENT = 'share-comment',
}

export const sendPriceProposalEmail = async (parameters: {
  message: string;
  license_plate: string;
  make: string;
  model: string;
  location_name: string;
  car_id: string;
}): Promise<void> => {
  const body = { parameters, template: EmailTemplate.SHARE_PRICE_PROPOSAL };
  return instance.post(`/mail`, body);
};

export const shareCar = async (
  parameters: { car_id: CarResult['id']; make: string; model: string; license_plate: string; text: string },
  recipients: string[],
): Promise<void> => {
  const body = { parameters, recipients, template: EmailTemplate.SHARE_CAR };
  return instance.post('/mail', body);
};

export const shareTaxation = async (
  parameters: { license_plate: string; text: string; price: number; url: string; tdc: number; etr: number },
  recipients: string[],
): Promise<void> => {
  const body = { parameters, recipients, template: EmailTemplate.SHARE_TAXATION };
  return instance.post('/mail', body);
};

export const sendCarMessageEmail = async (parameters: {
  license_plate: string;
  make: string;
  model: string;
  location_name: string;
  car_id: string;
}): Promise<void> => {
  const body = { parameters, template: EmailTemplate.SHARE_CAR_MESSAGE };
  return instance.post(`/mail`, body);
};

export const sendCsmMessageEmail = async (parameters: {
  url: string;
  comment: string;
  dealer: string;
  send_to_jira: boolean;
}): Promise<void> => {
  const body = { parameters, template: EmailTemplate.SHARE_CSM_COMMENT };
  return instance.post(`/mail`, body);
};
