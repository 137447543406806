import React, { useCallback } from 'react';
import { valueToSelectOptionT } from '@japieglobal/shared/src/components/inputs/select/utils';
import { MakeSelect } from '../selects/make-select';
import { ModelSelect } from '../selects/model-select';
import { FuelSelect } from '../selects/fuel-select';
import { BodySelect } from '../selects/body-select';
import { HpSelect } from '../selects/hp-select';
import { BuildSelect } from '../selects/build-select';
import { SelectOption, valueToSelectOption, ColoredButton } from '@japieglobal/shared';
import { useTranslation } from 'react-i18next';
import { OptionSelectProps } from '../selects/type';
import { Grid } from '@mui/material';

interface StandardOptionsSelectsProps {
  selectValues: {
    make?: string;
    model?: string;
    body?: string;
    fuel?: string;
    build?: number;
    hp?: number;
  };
  setSelectValues: (values: {
    make?: string;
    model?: string;
    body?: string;
    fuel?: string;
    build?: number;
    hp?: number;
  }) => void;
}

export const StandardOptionsSelects = ({ selectValues, setSelectValues }: StandardOptionsSelectsProps) => {
  const { make, model, fuel, body, hp, build } = selectValues;
  const { t } = useTranslation();

  const getSelectProps = useCallback(
    (
      keyName: keyof typeof selectValues,
    ): OptionSelectProps | { value?: SelectOption; setValue: (value: SelectOption) => void } => ({
      ...(keyName !== 'make' && { standardOption: { make, model, fuel, body, hp, build } }),
      setValue: (option: SelectOption) => setSelectValues({ ...selectValues, [keyName]: option.value }),
      value:
        keyName === 'make' || keyName === 'model'
          ? valueToSelectOption(selectValues[keyName])
          : valueToSelectOptionT(t, selectValues[keyName]),
    }),
    [body, build, fuel, hp, make, model, selectValues, setSelectValues, t],
  );

  return (
    <Grid container item flex={1} direction={'column'} rowGap={1}>
      <MakeSelect {...getSelectProps('make')} />
      <ModelSelect {...getSelectProps('model')} />
      <FuelSelect {...getSelectProps('fuel')} />
      <BodySelect {...getSelectProps('body')} />
      <BuildSelect {...getSelectProps('build')} />
      <HpSelect {...getSelectProps('hp')} />
      <ColoredButton onClick={() => setSelectValues({})}>Reset</ColoredButton>
    </Grid>
  );
};
