import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { ColoredButton, LoadingWrapper, TextInput, ToggleInput, valuesToSelectOptions } from '@japieglobal/shared';
import { SuperAdminPageContainer } from '../../super-admin-page-container';
import { noop } from '@japieglobal/shared/src/utils';
import { OptionInputs } from './option-inputs';
import { Box } from '@mui/material';
import { StandardOptions } from './standard-options/standard-options';
import { editOption, getOption, Option } from '@japieglobal/shared/src/api/services';
import { MakeMultiSelect } from './selects/make-multi-select';
import { ModelMultiSelect } from './selects/model-multi-select';

export const OptionsManagementEdit = () => {
  const [option, setOption] = useState<Option>({
    id: 0,
    name: '',
    makes: null,
    models: null,
    is_body: false,
    is_pseudo: false,
    commercial_vehicles_only: false,
    pattern: [],
    default_options: [],
    ban: [],
    super_pattern: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const { optionId } = useParams();
  const { snackbarErrorHandler, snackbarErrorMessage, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const handleGetOption = useCallback(() => {
    setIsLoading(true);
    if (optionId) {
      getOption(Number(optionId))
        .then(setOption)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    } else {
      snackbarErrorMessage('Unknown Option Id');
    }
  }, [optionId, snackbarErrorHandler, snackbarErrorMessage]);

  const handleEdit = () => {
    setIsLoading(true);
    if (optionId) {
      editOption(Number(optionId), option)
        .then(() => snackbarSuccessMessage('Option changes saved :D'))
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    handleGetOption();
  }, [handleGetOption]);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <SuperAdminPageContainer>
        <b>Edit Option</b>
        <Box display={'flex'} flexDirection={'column'} rowGap={'10px'}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} columnGap={'10px'}>
            <TextInput label="NAME" disabled setValue={noop} value={option.name} />
            <ToggleInput
              label={'BW_ONLY'}
              disableTripleState
              toggleState={option.commercial_vehicles_only}
              setToggleState={(v) => setOption({ ...option, commercial_vehicles_only: !!v })}
            />
            <MakeMultiSelect
              setValue={(value) => setOption({ ...option, makes: value.map((v) => v.value) })}
              value={valuesToSelectOptions(option.makes ?? [])}
            />
            <ModelMultiSelect
              standardOption={{
                make: option.makes?.[0] || '',
                body: null,
                build: null,
                fuel: null,
                hp: null,
                id: 0,
                model: null,
                option_id: 0,
                wheelbase: null,
              }}
              setValue={(value) => setOption({ ...option, models: value.map((v) => v.value) })}
              value={valuesToSelectOptions(option.models ?? [])}
            />
          </Box>
          <OptionInputs option={option} setOption={setOption} />
          <Box display={'flex'} justifyContent={'flex-end'}>
            <ColoredButton disabled={isLoading} onClick={handleEdit}>
              save
            </ColoredButton>
          </Box>
          <StandardOptions optionMake={option.makes?.[0]} />
        </Box>
      </SuperAdminPageContainer>
    </LoadingWrapper>
  );
};
